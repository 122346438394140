import './App.css';
import StoryScreen from'./components/StoryScreen';
import { Navigate, Route, Routes } from 'react-router-dom';
import DeleteConfirm from "./components/pages/DeleteConfirm"
import DeleteProfile from "./components/pages/DeleteProfile"
import Privacy from "./components/pages/Privacy"
import Terms from "./components/pages/Terms"
import HomePage from './components/HomePage';


function App() {
  return (
    <div className="App" style={{backgroundColor: "transparent"}}>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/story" element={<StoryScreen />} />
        <Route path="/delete-profile" element={<DeleteProfile />} />
        <Route path="/delete-profile-confirm" element={<DeleteConfirm />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms-of-service" element={<Terms />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </div>
  );
}

export default App;
