import React, { useCallback, useState } from 'react';
import { 
  Box, 
  IconButton, 
  Typography, 
  Paper, 
  Collapse,
  Stack,
  styled,
  SxProps,
  Theme,
  List,
  CircularProgress
} from '@mui/material';
import { 
  PlayArrow, 
  Pause, 
  ArrowForward,
  ExpandLess,
  ExpandMore
} from '@mui/icons-material';

// Custom styled components
const ProgressDot = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'active'
})<{ active?: boolean }>(({ theme, active }) => ({
  width: 8,
  height: 8,
  borderRadius: '50%',
  backgroundColor: active ? "#D8A363" : theme.palette.grey[600],
  transition: theme.transitions.create('background-color', {
    duration: theme.transitions.duration.short,
  }),
}));

export const PlayButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: "#D8A363",
  color: theme.palette.common.black,
  '&:hover': {
    backgroundColor: "#FF9F32",
    color: "#fff"
  },
  width: 40,
  height: 40,
  '& .MuiSvgIcon-root': {
    fontSize: 24,
  },
}));

interface ChapterPlayerProps {
  text: string;
  activeIndex: number;
  onPlay: () => void;
  onPause: () => void;
  onNext?: () => void;
  totalSteps?: number;
  sx?: SxProps<Theme>;  // Allow passing MUI sx prop
  containerSx?: SxProps<Theme>;  // Additional styling for the outer container
  playing?: boolean;
  chapterNumber?: number;
  loadingAudio?: boolean
}

const ChapterPlayer: React.FC<ChapterPlayerProps> = ({
  text,
  activeIndex,
  onPlay,
  onPause,
  onNext,
  totalSteps = 7,
  playing = false,
  chapterNumber = 1,
  loadingAudio = false
}) => {
  const [showText, setShowText] = useState(false);

  const handlePlayPause = useCallback(() => {
    if (playing) {
      onPause();
    } else {
      onPlay();
    }
  },[onPause, onPlay, playing]);


  return (
    <Box 
      sx={{ 
        bottom: 0,
        zIndex: 5,
        position: "absolute",
        width: "100%", 
        height: "auto", 
        justifyContent: 'center',
       
      }}
    >
      <Paper sx={{
            background: "linear-gradient(0deg, #131212 5%, transparent 100%)",
            color: 'common.white',
            height: "150px",
            borderRadius: '0px',
        }}
        />
      <Paper 
        sx={{
            backgroundColor: "#131212",
            color: 'common.white',
            paddingX: 4,
            paddingBottom: "50px",
            borderRadius: '0px',
        }}
      >
        <Box 
          sx={{ 
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2
          }}
        >
          <Typography variant="h6" style={{fontWeight: 600}}>
            {`Chapter ${chapterNumber}`}
          </Typography>
          
          <Box
            component="button"
            onClick={() => setShowText(!showText)}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 0.5,
              background: 'none',
              border: 'none',
              color: 'grey.400',
              cursor: 'pointer',
              '&:hover': { color: 'common.white' },
            }}
          >
            <Typography variant="body2" fontStyle={"italic"} style={{fontWeight: 500}}>
              Show text
            </Typography>
            {showText ? <ExpandMore fontSize="medium" /> : <ExpandLess fontSize="small" />}
          </Box>
        </Box>

        <Collapse in={showText}>
          <List style={{maxHeight: "40vh",  overflowY: "auto" , scrollbarWidth: 'none', marginBottom: "20px"}}>
            <Typography 
                variant="body2" 
                sx={{ 
                color: 'grey.300',
                mb: 2,
                fontSize: 18
                }}
                textAlign="left"
            >
                {text}
            </Typography>
          </List>   
        </Collapse>

        <Stack 
          direction="row" 
          spacing={2} 
          alignItems="center"
        >
          <Stack 
            direction="row" 
            spacing={1} 
            alignItems="center" 
            sx={{ flex: 1 }}
          >
            {Array.from({ length: totalSteps }).map((_, index) => (
              <ProgressDot key={index} active={index === activeIndex} />
            ))}
          </Stack>

          <Stack direction="row" spacing={1}>
            <PlayButton onClick={handlePlayPause} size="small">
                {loadingAudio ? (
                    <CircularProgress size={24} color="inherit" />
                ) : (
                    playing ? <Pause /> : <PlayArrow />
                )}
            </PlayButton>
            
            {onNext && (
              <IconButton
                size="small"
                sx={{
                  width: 40,
                  bgcolor: "#757575",
                  color: '#ccc',
                  '&:hover': { 
                    bgcolor: 'grey.400',
                    color: '#000',
                  }
                }}
                onClick={onNext}
              >
                <ArrowForward />
              </IconButton>
            )}
          </Stack>
        </Stack>
      </Paper>
    </Box>
  );
};

export default ChapterPlayer;