import React, { useState } from 'react';
import { Vortex } from 'react-loader-spinner'; 

const styles = {
  body: {
    width:"100%",
    display: "flex",
    justifyContent: "center",
    contentAlign: "center",
    alignItems: "center",
    backgroundColor: '#fff',
    color: '#000000',
    width: "100%",
    fontFamily: 'Comic Sans MS, sans-serif',
  },
  row: {
    minWidth: '320px',
    maxWidth: '600px',
    justifyContent: "center",
    contentAlign: "center",
    alignItems: "center",
 
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    wordBreak: 'break-word',
  },
  header: {
    backgroundColor: '#ffffff',
  },
  logo: {
    width: '48%',
    maxWidth: '288px',
    height: 'auto',
    display: 'inline-block',
  },
  heading: {
    color: '#5cc1ec',
    textAlign: 'center',
    fontSize: '44px',
    lineHeight: '61.6px',
    fontWeight: "bold",
    padding: "0px 20px"
  },
  textContainer: {
    backgroundColor: '#ffffff',
    padding: '25px 50px',
    textAlign: 'center',
  },
  text: {
    color: '#000000',
    fontSize: '18px',
    fontWeight: "bold",
    lineHeight: '25.2px',
    listStyleType: 'disc', // Ensure bullets are displayed
    paddingLeft: '0', // Remove default padding
    textAlign: 'center', // Align text to the left
  },
  containerBullets: {
    display: 'flex',
    justifyContent: 'center', // Center the container horizontally
  },
  textBulltes: {
    color: '#000000',
    fontSize: '18px',
    lineHeight: '25.2px',
    margin: '0',
    textAlign: 'left', // Align text to the left
  },
  listItem: {
    margin: '0', // Remove default margin
  },
  button: {
    backgroundColor: '#5cc1ec',
    color: 'white',
    padding: '10px 20px',
    fontSize: '24px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    marginTop: "32px",
  },
  input: {
    minWidth: "80%",
    padding: '12px',
    fontSize: '20px',
    borderRadius: '5px',
    marginTop: "32px",
  },
  confirmedMessage: {
    display: 'block',
    textAlign: 'center',
    fontSize: '18px',
    lineHeight: '25.2px',
    color: '#000000',
  },
  textConfirmation: {
    color: '#000000',
    marginTop: 40,
    fontSize: '20px',
    fontWeight: "bold",
    lineHeight: '25.2px',
    listStyleType: 'disc', // Ensure bullets are displayed
    paddingLeft: '0', // Remove default padding
    textAlign: 'center', // Align text to the left
  },
  spinnerContainer: {
    marginTop: '20px',
    textAlign: 'center',
    contentAlign: "center",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column"
  },
};

const DeleteProfile = () => {
  const [showConfirmation, setShowConfirmation] = useState(false);

  const sendGetRequest = () => {
    const email = document.getElementById('email').value;
    if (!isValidEmail(email)) {
      alert('Error: Please enter a valid email address.');
      return;
    }
    const url = `https://4471m6hcxd.execute-api.us-east-1.amazonaws.com/prod/send-delete-account-confirmation?email=${email}`;
    setLoading(true);
    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setShowConfirmation(true);
      })
      .catch(error => {
        console.error('Error:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [loading, setLoading] = useState(false);

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <div style={styles.body}>
        <div style={styles.row}>
          <div style={styles.header}>
            <img
              src="https://fable-demo-assets.s3.amazonaws.com/Fable-Logo.png"
              alt=""
              style={styles.logo}
            />
            <div style={styles.heading}>
              Sorry to See You Go!
            </div>
          </div>
          {loading ? 
            <div style={styles.spinnerContainer}>
              <Vortex 
                height="80" 
                width="80" 
                radius="9"
                colors={["#5cc1ec", "orange", "#5cc1ec","orange", "orange", "#5cc1ec"]}
                ariaLabel="three-dots-loading"
              />
              
          </div> :
          showConfirmation ? 
            <div style={styles.confirmedMessage}>
              <p style={styles.textConfirmation}>
                We've sent you a confirmation email. Please check your inbox to complete deleting your account.<br />
              </p>
            </div> :
          <div style={styles.textContainer}>
            <div id="delete-account-message">
              <p style={styles.text}>
                We respect your privacy. If you would like to request us to delete your account. Enter the email you've signed up with and press "Delete Account".<br />
              </p>
              <p style={styles.text}>
                We'll send a confirmation email. Once confirmed we'll be sure to delete the following:<br />
              </p>
              <div style={styles.containerBullets}>
                <ul style={styles.textBulltes}>
                  <li style={styles.listItem}>Account level information such as account id, email, and any tokens you've purchased within the app.</li>
                  <li style={styles.listItem}>All assets (associated with your account) such as images, audio, and text generated within the app.</li>
                  <li style={styles.listItem}>Any logs generated during the process will automatically be discarded after 30 days.</li>
                </ul>
              </div>
              <div>
                <input
                  type="text"
                  id="email"
                  name="email"
                  placeholder="Your Email"
                  style={styles.input}
                />
              </div>
              <div>
                <button onClick={sendGetRequest} style={styles.button}>
                  Delete Account
                </button>
              </div>
            </div>
          </div>
          }
        </div>
    </div>
  );
};

export default DeleteProfile;
