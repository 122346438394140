import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import AudioPlayer from 'react-h5-audio-player';
import { PlayArrow } from '@mui/icons-material';
import { Box, Button, Typography, CircularProgress, Paper } from '@mui/material';
import ChapterPlayer, { PlayButton } from './ChapterPlayer';
import './StoryScreen.css'
import FableModal from './FableModal';
const loadingSource = require('./images/loading_book.png');
const errorSource = require('./images/fable_error.png');


const StoryScreen = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [currentChapterNumber, setCurrentChapterNumber] = useState(1);
  const storyId = queryParams.get('storyId');
  const userId = queryParams.get('userId');
  const chapterIds = queryParams.get('chapterIds');
  const [chapters, setChapters] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const audioRef = useRef<any>(null)
  const [paragraphNumber, setParagraphNumber] = useState<number>(0);
  const [playing, setPlaying] = useState<boolean>(false)
  const [loadingAudio, setLoadingAudio] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [overlayPlay, setOverlayPlay] = useState(false)
  const [downloadStory, setDownloadStory] = useState(false)

  let dataSource = chapters && chapters.length > 0 ? chapters[currentChapterNumber - 1] : {};
  const { imageUrls, audioUrl, audioUrls, narrative, storyName, chapterNumber = 1 } = dataSource;

  const narrativeParagraphs = useMemo(() => {
    if (!Array.isArray(narrative) && imageUrls && imageUrls.length > 0) {
      const sentences = narrative.match(/[^.!?]+[.!?]+/g) || [narrative];
      const numChunks = imageUrls.length;
      const chunkSize = Math.floor(sentences.length / numChunks);
      const remainder = sentences.length % numChunks;

      const narrativeChunks = [];
      let index = 0;

      for (let i = 0; i < numChunks; i++) {
        const currentChunkSize = chunkSize + (i < remainder ? 1 : 0);
        narrativeChunks.push(
          sentences
            .slice(index, index + currentChunkSize)
            .join(' ')
            .trim(),
        );
        index += currentChunkSize;
      }

      return narrativeChunks;
    } else {
      return narrative;
    }
  }, [imageUrls, narrative]);

  const track = useMemo(() => {
    if (audioUrl) {
      return audioUrl
    } else if (audioUrls && audioUrls.length > 0) {
      return audioUrls[paragraphNumber]
    }
  },[audioUrl, audioUrls, paragraphNumber])

  const onNextTrack = useCallback(() => {
    if (paragraphNumber + 1 < narrativeParagraphs.length) {
      setParagraphNumber(paragraphNumber + 1);
    } else {
      if (
        (chapters[chapterNumber] && chapters[chapterNumber].pending) ||
        chapterNumber === chapters.length
      ) {
        setDownloadStory(true)
        if (audioRef.current) {
          audioRef.current.audio.current.pause(); // Plays the audio
          setPlaying(false)
        }
      } else {
        setCurrentChapterNumber(currentChapterNumber + 1);
        setParagraphNumber(0);
      }
    }
  }, [chapterNumber, chapters, currentChapterNumber, narrativeParagraphs, paragraphNumber]);

  const navigate = useNavigate();

  const handleGoHome = useCallback(() => {
    navigate('/');
  },[navigate])

  const onDownloadAndroid = useCallback(() => {
    window.open("https://play.google.com/store/apps/details?id=com.fableapp", "_blank");
  }, []);
  
  const onDownloadIOS = useCallback(() => {
    window.open("https://apps.apple.com/us/app/fable-app/id6497625686", "_blank");
  }, []);

  useEffect(() => {
    const fetchStory = async () => {
      if (storyId && userId) {
        try {
          const body = {
            userId,
            storyIds: chapterIds ? chapterIds.split(',') : [storyId],
          };
          const storyResult = await axios.post('https://4471m6hcxd.execute-api.us-east-1.amazonaws.com/prod/stories-by-story-ids', body, {
            headers: {
              'Content-Type': 'application/json',
            },
          });
          console.log(storyResult)
          if (storyResult.data && storyResult.data.stories && storyResult.data.stories.length > 0) {
            const updatedChapters = storyResult.data.stories.sort((a: any, b: any) => a.createDate - b.createDate);
            setChapters(updatedChapters);
          }
        } catch (error) {
          console.error(error)
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    fetchStory();
  }, [chapterIds, storyId, userId]);

  useEffect(() => {
    setOverlayPlay(true)
  }, []);

  const onPauseCallback = useCallback(() => {
    if (audioRef.current) {
      audioRef.current.audio.current.pause(); // Plays the audio
      setPlaying(false)
    }
  },[])

  const onPlayCallback = useCallback(() => {
    if (audioRef.current) {
      audioRef.current.audio.current.play(); // Plays the audio
      setPlaying(true)
      setOverlayPlay(false)
    }
  },[])

  const onPlayExecuted = useCallback(() => {setPlaying(true)},[]) 
  const onImageLoad = useCallback(() => setIsImageLoaded(true),[])
  const onLoadAudioStart = useCallback(() => { setLoadingAudio(true)},[])
  const onLoadAudioEnd = useCallback(() => { setLoadingAudio(false)},[])
  
  return (
    <>
      {loading ? (
        <Box className="loadingScreen" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <CircularProgress />
          <Typography variant="h6">Loading story...</Typography>
        </Box>
      ) : (
        <>
        {downloadStory && <FableModal onDownloadAndroid={onDownloadAndroid} onDownloadIOS={onDownloadIOS} onCancel={handleGoHome}/>}
        {overlayPlay && 
          <Box className="overlayBox">
            <PlayButton
              onClick={onPlayCallback}
              style={{ width: 80, height: 80, backgroundColor: "#ccc" }}
            >
              <PlayArrow style={{ fontSize: 40 }} />
            </PlayButton>
            <Box
              style={{
                maxWidth: "75%",
                textAlign: "center",
                marginTop: 16,
              }}
            >
              <Typography
                variant="body1"
                style={{
                  fontWeight: 600,
                  color: "white",
                  fontSize: "20px"
                }}
              >
                Click play to watch and hear the story
              </Typography>
            </Box>
          </Box>
        }
        <Box className="storyScreen">
          {chapters ? (
            <Box className="storyContent">
              <Box className="imageWrapper">
                <img 
                  src={isImageLoaded ? imageUrls && imageUrls[paragraphNumber] : loadingSource} 
                  alt="Story"
                  onLoad={onImageLoad} 
                  style={{     
                    width: '100%', 
                    height: '100%',
                    objectFit: 'cover',  
                  }} 
                />
                <Paper 
                  elevation={0}
                  sx={{
                    backgroundColor: "#131212",
                    color: 'common.white',
                    paddingTop: "20px",
                    width: "100%",
                    position: "absolute",
                    top:0,
                    borderRadius: 0
                  }}
                >
                <Typography variant="h6" style={{fontWeight: 500}}>
                  {storyName}
                </Typography>
                <Paper 
                  elevation={0}
                  sx={{
                    background: "linear-gradient(180deg, #131212 10%, transparent 100%)",
                    color: 'common.white',
                    height: "150px",
                    width: "100%",
                    position: "absolute",
                    borderRadius: 0
                  }}
                />
                </Paper>

                <Box className="overlay">
                  <ChapterPlayer
                    text={narrativeParagraphs[paragraphNumber]}
                    activeIndex={paragraphNumber}
                    totalSteps={narrativeParagraphs.length}
                    onPlay={onPlayCallback}
                    onPause={onPauseCallback}
                    onNext={onNextTrack}
                    playing={playing}
                    chapterNumber={currentChapterNumber}
                    loadingAudio={loadingAudio}
                  />
                </Box>
              </Box>
              <Box className="audioControls" position="absolute" bottom={0} width="100%" bgcolor="rgba(0, 0, 0, 0.5)" display="none" zIndex={4}>
                <AudioPlayer
                  ref={audioRef}
                  src={track}
                  style={{ backgroundColor: 'transparent' }}
                  onEnded={onNextTrack}
                  onPlay={onPlayExecuted}
                  autoPlay={false}
                  onLoadStart={onLoadAudioStart}
                  onLoadedData={onLoadAudioEnd}
                />
              </Box>
            </Box>
          ) : (
            <Box className="errorPage" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
              <Box className="overlay" width="100%" height="100%" position="absolute" zIndex={5} bgcolor="rgba(0, 0, 0, 0.3)" />
              <img src={errorSource} alt="Sad Face" className="errorImage" />
              <Box className="buttonContainer" display="flex" justifyContent="center" padding={2}>
                <Typography variant="h6" color="error">Story could not be found...</Typography>
                <Button variant="contained" onClick={handleGoHome} sx={{ marginTop: 2, fontWeight: 'bold', borderRadius: 50 }}>
                  Home Page
                </Button>
              </Box>
            </Box>
          )}
        </Box>
        </>
      )}
    </>
  );
};

export default StoryScreen;
