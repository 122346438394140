import { Box, Typography, Button, Card, CardContent, CardMedia } from '@mui/material';
const fableScreen = require('./images/fable_logo.png');

interface FableModalProps {
    onDownloadAndroid?: () => void
    onDownloadIOS?: () => void
    onCancel?: () => void
}

const FableModal = ({ onDownloadIOS, onDownloadAndroid, onCancel }: FableModalProps) => {
    return (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.65)',
            zIndex: 1000,
            
       
          }}
        >
          <Card
            sx={{
              maxWidth: 350,
              backgroundColor: '#3b3b3b',
              boxShadow: '0 4px 10px rgba(0, 0, 0, 0.25)',
              color: '#FFFFFF',
              textAlign: 'center',
              opacity: 0.9,
              padding: 2,
              borderRadius: 4
            }}
          >
            <CardContent>
              <Typography variant="h5" sx={{ marginBottom: "20px", fontWeight: 550 }}>
                Want to create your own stories? Try Fable today!
              </Typography>
              <CardMedia
                component="img"
                image={fableScreen}
                alt="Fable Logo"
                sx={{
                  width: 100,
                  height: 100,
                  margin: '10px auto',
                  borderRadius: '12px',
                }}
              />
              <Typography variant="body1" sx={{  fontSize: "16px", marginBottom: 3, lineHeight: 1.3, padding: 2 }}>
                Download and try Fable today to create your own magical stories for you and your child to enjoy together!
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'center', gap: 2 }}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: '#fff',
                    textTransform: 'none', 
                    color: '#000',
                    '&:hover': { backgroundColor: '#D8A363', color: "white" },
                    fontSize: "16px", 
                    borderRadius: "50px",
                    whiteSpace: 'nowrap',
                    width: "75%",
                    alignSelf: "center",
                    fontWeight: 600
                  }}
                  onClick={onDownloadAndroid}
                >
                  Download Android
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: '#fff',
                    textTransform: 'none', 
                    color: '#000',
                    '&:hover': { backgroundColor: '#76A9E7', color: "white" },
                    fontSize: "16px", 
                    borderRadius: "50px",
                    width: "75%",
                    alignSelf: "center",
                    fontWeight: 600
                  }}
                  onClick={onDownloadIOS}
                >
                  Download IOS
                </Button>
                <Button
                  onClick={onCancel}
                  variant="outlined"
                  sx={{
                    borderColor: 'transparent',
                    textTransform: 'none', 
                    color: '#ccc',
                    '&:hover': { borderColor: 'transparent',background: "none", color: '#FFFFFF' },
                    fontSize: "16px", 
                    borderRadius: "50px",
                    fontWeight: 600
                  }}
                >
                  Maybe Later
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Box>
      );
    };

export default FableModal;