import { Typography } from '@mui/material';
import './BulletText.css';
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; 

interface BulletTextProps {
    text: string
    isBold: boolean
}
const BulletText = ({ text, isBold }: BulletTextProps) => {
    return (
    <div className="bullet">
        <div className="bulletIconContainer">
          <CheckCircleIcon className="checkIcon" /> {/* Using Material Icon */}
        </div>
        <Typography
          variant={isBold ? "h6" : "body1"}
          className={isBold ? "sectionDescriptionBold" : "sectionDescription"}
        >
          {text}
        </Typography>
      </div>
    );
  };

export default BulletText;