import React from 'react';

const DeleteConfirm = () => {
  const styles = {
    body: {
      margin: 0,
      padding: 0,
      backgroundColor: '#fff',
      color: '#000000',
      fontFamily: 'Comic Sans MS, sans-serif',
    },
    container: {
      height: '100vh',
      borderCollapse: 'collapse',
      tableLayout: 'fixed',
      borderSpacing: '0',
      minWidth: '320px',
      margin: '0 auto',
      backgroundColor: '#ffffff',
      width: '100%',
      
    },
    row: {
      margin: '0 auto',
      minWidth: '320px',
      maxWidth: '600px',
      overflowWrap: 'break-word',
      wordWrap: 'break-word',
      wordBreak: 'break-word',
      backgroundColor: 'transparent',
    },
    content: {
      backgroundColor: '#ffffff',
      alignContent: "center",
      width: '100% !important',
    },
    text: {
      fontFamily: 'Comic Sans MS, sans-serif',
    },
    image: {
      outline: 'none',
      textDecoration: 'none',
      msInterpolationMode: 'bicubic',
      clear: 'both',
      display: 'inline-block !important',
      border: 'none',
      height: 'auto',
      float: 'none',
      width: '48%',
      maxWidth: '288px',
    },
    heading: {
      fontSize: '44px',
      lineHeight: '61.6px',
      color: '#5cc1ec',
      fontFamily: 'Comic Sans MS, sans-serif',
    },
    paragraph: {
      color: '#000000',
      fontSize: '20px',
      lineHeight: '25.2px',
      fontWeight: "bold"
    },
  };

  return (
    <div style={styles.body}>
    <table style={styles.container} cellPadding="0" cellSpacing="0">
      <tbody>
        <tr>
          <td>
            <div style={styles.row}>
              <div style={styles.row}>
                <table style={styles.text} role="presentation" cellPadding="0" cellSpacing="0" width="100%" border="0">
                  <tbody>
                    <tr>
                      <td style={{ padding: '5px' }}>
                        <table height="0px" align="center" border="0" cellPadding="0" cellSpacing="0" width="100%">
                          <tbody>
                            <tr>
                              <td style={{ fontSize: '0px', lineHeight: '0px' }}>
                                <span>&#160;</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div style={styles.row}>
              <div style={styles.content}>
                <table style={styles.text} role="presentation" cellPadding="0" cellSpacing="0" width="100%" border="0">
                  <tbody>
                    <tr>
                      <td style={{ padding: '0px' }} align="center">
                        <img
                          align="center"
                          src="https://fable-demo-assets.s3.amazonaws.com/Fable-Logo.png"
                          alt=""
                          title=""
                          style={styles.image}
                          width="288"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table style={styles.text} role="presentation" cellPadding="0" cellSpacing="0" width="100%" border="0">
                  <tbody>
                    <tr>
                      <td style={{ padding: '10px', textAlign: 'center',  }}>
                        <div style={{ color: '#ffffff', textAlign: 'center', lineHeight: '140%' }}>
                          <p style={{ fontSize: '14px', lineHeight: '140%' }}>
                            <span style={styles.heading}>
                              <strong>Fable Account Removal Confirmation</strong>
                            </span>
                          </p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div style={styles.row}>
              <div style={styles.content}>
                <table style={styles.text} role="presentation" cellPadding="0" cellSpacing="0" width="100%" border="0">
                  <tbody>
                    <tr>
                      <td style={{ padding: '25px 75px', textAlign: 'center' }}>
                        <div id="delete-account-message" style={{ display: 'block', textAlign: 'flex-start', lineHeight: '140%' }}>
                          <p style={{ fontSize: '14px', lineHeight: '140%' }}>
                            <span style={styles.paragraph}>
                              Your account has been successfully removed. We hope to see you back soon!<br />
                            </span>
                          </p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div style={styles.row}>
              <div style={styles.row}>
                <table style={styles.text} role="presentation" cellPadding="0" cellSpacing="0" width="100%" border="0">
                  <tbody>
                    <tr>
                      <td style={{ padding: '10px' }}>
                        <table height="0px" align="center" border="0" cellPadding="0" cellSpacing="0" width="100%">
                          <tbody>
                            <tr>
                              <td style={{ fontSize: '0px', lineHeight: '0px' }}>
                                <span>&#160;</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    </div>
  );
};

export default DeleteConfirm